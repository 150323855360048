<template>
  <div class="tab-contents-wrap">
    <div class="title-wrap">
      <h3 class="title">{{eventInfo.name}}</h3>
      <div class="header-info">
        <ul>
          <li class="date">
            {{ getDateString(eventInfo.startDate.replace(/\./g, '-'), eventInfo.endDate.replace(/\./g, '-')) }}
          </li>
          <li class="date v-bar">
            {{eventInfo.placeName || eventInfo.placeDetail}}
          </li>
        </ul>
      </div>
    </div>

    <template v-if="statusType === 1">
    <div class="form-container">
      <ValidationObserver ref="form">
      <form>
        <fieldset>
          <legend>{{$t('content.event.Event.txt33')}}</legend>
          <ul>
            <li class="form-row">
              <ValidationProvider
                :rules="{ required: true }"
                :name="$t('content.event.Event.txt49')"
                v-slot="{ errors }"
              >
                <BaseInput
                  v-model="member_name"
                  :label="$t('content.event.Event.txt49')"
                  :class="{'error':errors[0]}"
                  :placeholder="$t('content.event.Event.txt50')"
                  required="">
                </BaseInput>
                <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
              </ValidationProvider>
            </li>
            <li class="form-row">
              <ValidationProvider
                :rules="{ required: true, email: true }"
                :name="$t('content.event.Event.txt51')"
                v-slot="{ errors }"
              >
                <BaseInput
                  v-model="member_email"
                  :class="{'error':errors[0]}"
                  :label="$t('content.event.Event.txt51')"
                  :placeholder="$t('content.event.Event.txt52')"
                  required="">
                </BaseInput>
                <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
              </ValidationProvider>
            </li>
            <li class="form-row">
              <ValidationProvider
                :rules="{ required: true, phone:true }"
                :name="$t('content.event.Event.txt53')"
                v-slot="{ errors }"
              >
                <TelInput
                  v-model="phone"
                  :label="$t('content.event.Event.txt53')"
                  :placeholder="$t('content.event.Event.txt54')"
                  :class="{'error':errors[0]}"
                  required
                  maxlength="13"
                />
                <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
              </ValidationProvider>
            </li>
            <li class="form-row">
              <BaseTextarea
                v-model="memo"
                :label="$t('content.event.Event.txt55')"
                size="large"
                :placeholder="$t('content.event.Event.txt56')"
              />
            </li>
            <li class="form-row">
                <div class="input-title">
                  {{$t("signUp.title.agreement")}}
                </div>
                <div class="terms-area">
                  <BaseCheckbox v-model="agreed" required>{{$t('content.event.Event.txt60')}}
                    </BaseCheckbox>
                    <div class="terms-container">
                    <ol>
                        <li>{{$t('content.event.Event.txt57')}}</li>
                        <li>{{$t('content.event.Event.txt58')}}</li>
                        <li>{{$t('content.event.Event.txt59')}}
                        </li>
                    </ol>
                    </div>
                </div>
              </li>
          </ul>
        </fieldset>
      </form>
      </ValidationObserver>
    </div>
    <!-- 버튼 -->
    <FixedButton :isFooter="isFooter">
      <template slot="buttons">
        <button @click="goBack" class="btn-ghost">Back</button>
        <button
          @click="apply"
          :disabled="!agreed"
          class="btn-basic"
        >{{$t('content.event.Event.txt61')}}
        </button>
      </template>
    </FixedButton>
    <!-- // 버튼 -->
    </template>

    <template v-if="statusType === 2">
      <!-- 이벤트 신청 성공결과 -->
      <div class="reservation-result-wrap">
          <div class="reservation-result" data-aos="flip-right">
              <dl class="completed">
                  <dt>{{$t('content.event.Event.txt62')}}</dt>
                  <dd class="desc">
                  </dd>
              </dl>
          </div>
      </div>
      <div class="btn_bottom_wrap fixed perspective">
        <button class="btn-basic" @click="$router.push({name:'EventDetail', params: {eventNo:eventNo}})">
          {{$t('content.event.Event.txt63')}}
        </button>
      </div>
      <!-- // 이벤트 신청 성공결과 -->
    </template>
    <template v-if="statusType === 3">
      <!-- 이벤트 신청 에러결과 -->
      <div class="reservation-result-wrap">
          <div class="reservation-result" data-aos="flip-right">
              <dl class="failed">
                  <dt>{{$t('content.event.Event.txt64')}}</dt>
                  <dd class="desc" v-html="errMsg">
                  </dd>
              </dl>
          </div>
      </div>
      <!-- // 이벤트 신청 에러결과 -->
      <div class="btn-wrap perspective">
        <button class="btn-basic" @click="$router.push({name:'EventDetail', params: {eventNo:eventNo}})">
          {{$t('content.event.Event.txt63')}}
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import ApplyNonMember from '@/views/event/ApplyNonMember.vue';
import FixedButton from '@/components/base/FixedButton.vue';

export default {
  name: 'MobileApplyNonMember',
  extends: ApplyNonMember,
  components: {
    FixedButton,
  },
  mounted() {
    // footer 유무 설정
    this.setFooter(false);
  },
};
</script>
